import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "../styles/style.css"
import "../styles/course.css"

const Title = ({ title, subTitle }) => (
  <div className="row justify-content-center">
    <div className="col-lg-5">
      <div className="main_title">
        <h2>{title}</h2>
        {subTitle && <p>{subTitle}</p>}
      </div>
    </div>
  </div>
)

const WelcomeText = () => (
  <section className="feature-area mb-5">
    <div className="container">
      <Title title="Welcome to London Adult Education College" />
      <p>
        Completing your PCO Licence will be hassle free. We are the cheapest and
        one of London’s only all in one centre where you can complete your
        Topographical training, CRB check, medical and have your application
        correctly filled in by us, taking the headache out of the PCO
        application process. We offer applicants our expertise in forming a PCO
        application, passing the topographical test and sourcing jobs for you to
        become a fully licensed Uber or minicab driver as quickly as possible.
        Get in contact today to join thousands of other new applicants on the
        road!
      </p>
    </div>
  </section>
)

const InquiryButton = () => (
  <section className="feature-area mb-5">
    <Link to="/contact">
      <span className="btn-lg btn-primary" style={{fontSize: '3.25rem'}}>Enquiries</span>
    </Link>
  </section>
)

const Programs = ({ data }) => (
  <section className="special_cource pt-4 pb-4">
    <div className="container">
      <Title title="Our Programs" subTitle="Available programs" />
      <div className="row">
        {data.map(({ node }) => (
          <div className="col-sm-6 col-lg-4 mb-4" key={node.frontmatter.path}>
            <div className="single_special_cource">
              <Img
                fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                className="special_img mb-0"
                alt=""
              />
              <div className="special_cource_text md-0">
                <Link to={node.frontmatter.path}>
                  <span className="btn_4">{node.frontmatter.title}</span>
                </Link>
                <p>{node.frontmatter.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

const Index = props => {
  const data = props.data.allMarkdownRemark.edges
  return (
    <Layout title="Home">
      <WelcomeText />
      <Programs data={data} />

      <InquiryButton />
    </Layout>
  )
}
export default Index

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
      edges {
        node {
          frontmatter {
            title
            description
            date
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
